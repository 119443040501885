import { notification } from "antd";
import DELETE_ROLE from "./deleteRole.graphql";
import { DeleteRole, DeleteRoleVariables } from "./types/DeleteRole";
import React, { FC, useCallback } from "react";
import GraphQLConfirmButton, {
  GraphQLConfirmButtonProps,
} from "components/graphQLConfirmButton";

type DeleteRoleButtonBaseProps = GraphQLConfirmButtonProps<
  DeleteRole,
  DeleteRoleVariables
>;

interface DeleteRoleButtonProps
  extends Omit<
    DeleteRoleButtonBaseProps,
    "mutation" | "title" | "onCompleted"
  > {
  roleId: number;
  roleName: string;
}

type Props = DeleteRoleButtonProps;

const DeleteRoleButton: FC<Props> = ({
  roleId: id,
  roleName,
  options: { variables, ...options } = {},
  ...props
}) => {
  const handleCompleted = useCallback<
    NonNullable<DeleteRoleButtonBaseProps["onCompleted"]>
  >(
    ({ errors }) =>
      !errors &&
      notification.success({
        message: "Успех!",
        description: (
          <>
            Роль <strong>{roleName}</strong> была успешно удалена
          </>
        ),
      }),
    [roleName]
  );

  return (
    <GraphQLConfirmButton<DeleteRole, DeleteRoleVariables>
      mutation={DELETE_ROLE}
      options={{
        variables: { id, ...variables },
        ...options,
      }}
      title={
        <>
          Вы уверены что хотите удалить роль <strong>{roleName}</strong>?
        </>
      }
      onCompleted={handleCompleted}
      {...props}
    />
  );
};

export default DeleteRoleButton;
