import { DocumentNode, ExecutionResult } from "graphql";
import { Popconfirm, Button } from "antd";
import { PopconfirmProps } from "antd/lib/popconfirm";
import { MutationHookOptions, useMutation } from "@apollo/react-hooks";
import { OperationVariables } from "apollo-client";
import { ButtonProps } from "antd/lib/button";
import React, { useCallback } from "react";

export interface GraphQLConfirmButtonProps<
  TData = any,
  TVariables = OperationVariables
> extends PopconfirmProps {
  mutation: DocumentNode;
  options?: MutationHookOptions<TData, TVariables>;
  buttonProps?: ButtonProps;
  onCompleted?: (data: ExecutionResult<TData>) => void;
}

const GraphQLConfirmButton = <
  TData extends {} = any,
  TVariables extends {} = OperationVariables
>({
  mutation,
  options,
  buttonProps: {
    ghost = true,
    icon = "delete",
    size = "small",
    type = "danger",
    loading = false,
    ...buttonProps
  } = {},
  placement = "topRight",
  children = "Уд.",
  onConfirm,
  onCompleted,
  ...props
}: GraphQLConfirmButtonProps<TData, TVariables>) => {
  const [mutate, { loading: mutationLoading }] = useMutation(mutation, options);

  const handleConfirm = useCallback<NonNullable<PopconfirmProps["onConfirm"]>>(
    async e => {
      onConfirm && onConfirm(e);

      if (e && !e.isDefaultPrevented()) {
        const result = await mutate();
        onCompleted && onCompleted(result);
      }
    },
    [mutate, onCompleted, onConfirm]
  );

  return (
    <Popconfirm placement={placement} {...props} onConfirm={handleConfirm}>
      <Button
        ghost={ghost}
        icon={icon}
        loading={loading || mutationLoading ? { delay: 300 } : false}
        size={size}
        type={type}
        {...buttonProps}
      >
        {children}
      </Button>
    </Popconfirm>
  );
};

export default GraphQLConfirmButton;
