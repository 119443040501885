import { Button, Tag } from "antd";
import { ColumnProps } from "antd/lib/table";
import { navigate } from "gatsby";
import idx from "idx";
import React, { FC, useCallback, useMemo } from "react";
import GET_ROLE_LIST from "./getRoleList.graphql";
import {
  GetRoleList,
  GetRoleList_roles_items as Role,
  GetRoleListVariables,
} from "./types/GetRoleList";
import Authorize from "components/authorize";
import DeleteRoleButton from "components/deleteRoleButton";
import GraphQLTable, {
  creationTime,
  lastModificationTime,
  useColumnSearch,
} from "components/graphQLTable";
import MainLayout from "components/layouts/main";
import routes from "routes";

const RoleList: FC = () => {
  const handleEdit = useCallback(
    (id: string) => () => navigate(routes.roleEditToPath({ id })),
    []
  );

  const { createColumn: createNameColumn } = useColumnSearch<Role>("name", {
    dataSearchIndex: "normalizedName",
    useDefaultNormalizer: true,
  });

  const columns = useMemo<Array<ColumnProps<Role>>>(
    () => [
      { dataIndex: "id", title: "#", sorter: true },
      createNameColumn({
        title: "Наименование",
        sorter: true,
      }),
      {
        key: "permissions",
        render: (_test, { permissions }) => {
          if (!permissions) {
            return null;
          }

          return (
            <div style={{ marginTop: "-4px" }}>
              {permissions.map(({ id, name, isGranted }) => (
                <Tag
                  key={id}
                  color={isGranted ? "green" : "red"}
                  style={{ marginTop: "4px" }}
                >
                  {name}
                </Tag>
              ))}
            </div>
          );
        },
        title: "Разрешения",
      },
      creationTime,
      lastModificationTime,
      {
        key: "actions",
        width: "180px",
        render: (_text, { id, isStatic, name }) => (
          <>
            <Button
              icon="edit"
              className="mr-1"
              size="small"
              type="primary"
              ghost={true}
              onClick={handleEdit(id)}
            >
              Ред.
            </Button>
            {!isStatic && (
              <DeleteRoleButton
                roleId={parseInt(id, 10)}
                roleName={name}
                options={{
                  refetchQueries: [`GetRoleList`],
                }}
              />
            )}
          </>
        ),
      },
    ],
    [createNameColumn, handleEdit]
  );

  const handleNewRoleClick = useCallback(() => navigate(routes.roleCreate), []);

  const getDataSource = useCallback(
    (data?: GetRoleList) => idx(data, x => x.roles),
    []
  );

  return (
    <Authorize>
      <MainLayout
        title="Роли"
        extra={
          <Button icon="plus" type="primary" onClick={handleNewRoleClick}>
            Новая роль
          </Button>
        }
      >
        <GraphQLTable<Role, GetRoleList, GetRoleListVariables>
          columns={columns}
          query={GET_ROLE_LIST}
          getDataSource={getDataSource}
        />
      </MainLayout>
    </Authorize>
  );
};

export default RoleList;
